import React, { useRef, useState } from 'react';
import { useCursorPosition } from '../../lib/hooks';
import { Cursor, Container } from './cursor.styles';

const variants = {
	small: {
		scale: 1,
		transition: {
			duration: 0.5,
			type: 'tween',
		},
	},
	large: {
		scale: 3,
		transition: {
			duration: 0.5,
			type: 'tween',
		},
	},
	exit: {
		scale: 0,
		transition: {
			duration: 0.5,
			type: 'tween',
		},
	},
};

const CursorComp = ({ size, ref, visible, cursorsize = 'false' }) => {
	const { cursorYSpring, cursorXSpring } = useCursorPosition();

	return (
		<Cursor
			cursorsize={cursorsize}
			visible={visible.toString()}
			animate={size && visible ? 'large' : !size && visible ? 'small' : 'exit'}
			variants={variants}
			ref={ref}
			style={{
				translateX: cursorXSpring,
				translateY: cursorYSpring,
			}}
		/>
	);
};

export default CursorComp;
