import { useEffect, useRef, useCallback, useState, useReducer, useMemo } from 'react';

import { animations, motion, useMotionValue, useSpring, useTransform, useScroll, useInView } from '../styles/base/_allBase.styles';

function useCursorChange(initialValue = false) {
	const [size, setSize] = useState(initialValue);
	const [visible, setVisible] = useState(initialValue);
	const [rotate, setRotate] = useState(initialValue);
	const toggle = useCallback(() => {
		setSize((size) => !size);
	}, []);
	const hidden = useCallback(() => {
		setVisible((visible) => !visible);
	}, []);
	const rotation = useCallback(() => {
		setRotate((rotate) => !rotate);
	}, []);
	return [size, toggle, visible, hidden, rotate, rotation];
}

const useCursorPosition = () => {
	const cursorX = useMotionValue(-100);
	const cursorY = useMotionValue(-100);

	const springConfig = { damping: 20, stiffness: 300 };
	const cursorXSpring = useSpring(cursorX, springConfig);
	const cursorYSpring = useSpring(cursorY, springConfig);

	useEffect(() => {
		const moveCursor = (e) => {
			cursorX.set(e.clientX - 26);
			cursorY.set(e.clientY - 26);
		};

		window.addEventListener('mousemove', moveCursor);

		return () => {
			window.removeEventListener('mousemove', moveCursor);
		};
	}, []);

	return { cursorXSpring, cursorYSpring };
};

const useWrap = (initialArray = []) => {
	const length = initialArray.length - 1;
	const [index, setIndex] = useState(0);

	function wrapIndex(wrap) {
		if (index === length && wrap === 'next') {
			return setIndex(0);
		} else if (index <= length && wrap === 'next') {
			return setIndex(index + 1);
		} else if (index === 0 && wrap === 'prev') {
			return setIndex(length);
		} else if (index > length && wrap === 'prev') {
			return setIndex(index - 1);
		} else if (typeof wrap === 'number') {
			return setIndex(wrap);
		} else {
			return setIndex(0);
		}
	}

	return [index, wrapIndex];
};

const useCarousel = (children, timeOut = 1000) => {
	const [visible, setVisible] = useState(true);
	const [imageIndex, setImageIndex] = useWrap(children);

	const paginate = (n) => {
		setImageIndex(n);
	};

	const changeImage = (n) => {
		setVisible(false);

		setTimeout(() => {
			paginate(n);
			setVisible(true);
		}, timeOut);
	};

	return [imageIndex, visible, changeImage];
};

function useParallax(value, distance) {
	const result = useTransform(value, [0, 1], [-distance, distance]);
	return useSpring(result, {
		stiffness: 200,
		damping: 100,
		restDelta: 0.001,
	});
}

function useParallaxInView(distance = 25) {
	const ref = useRef(null);
	const { scrollYProgress } = useScroll({ target: ref });
	const y = useParallax(scrollYProgress, distance);
	const isInView = useInView(ref, { ...animations.inViewOptions });
	return { y, ref, isInView };
}

function useFilter(array = [], filterObject = {}) {
	const [focus, setFocus] = useState(0);
	const [filteredMarkers, setFilteredMarkers] = useState(array);

	const reducer = (state, action) => {
		switch (action.type) {
			case 'TOGGLE':
				return { ...state, [action.value]: !state[action.value] };
			default:
				return state;
		}
	};
	const [filter, setFilter] = useReducer(
		reducer,
		Object.keys(filterObject).reduce((accumulator, key) => ({ ...accumulator, [key]: true }), {})
	);

	useEffect(() => {
		let arrayFilter = [];
		const filterArray = Object.entries(filter).forEach(([key, value]) => {
			if (value === true) return arrayFilter.push(key);
		});

		const data = array.filter((array) => arrayFilter.some((filter) => filter.toLowerCase() === array.category.toLowerCase()));
		setFilteredMarkers(data);
	}, [filter]);

	return { filter, setFilter, focus, setFocus, filteredMarkers };
}

function useDragAndDrop(array) {
	const [state, setState] = useState({
		tasks: array.reduce((a, v) => ({ ...a, [v._id]: v }), {}),
		columns: {
			column1: {
				id: 'column1',
				title: 'Location',
				taskIds: array.reduce((a, v) => {
					return [...a, v._id];
				}, []),
			},
			column2: {
				id: 'column2',
				title: 'Trip',
				taskIds: [],
			},
		},
		columnOrder: ['column1', 'column2'],
	});
	const tasks = state.columns.column2.taskIds.map((taskID, i) => state.tasks[taskID]);
	const updateState = (array) => {
		const currentState = state.columns.column2.taskIds;
		setState({
			tasks: state.tasks,
			columns: {
				column1: {
					id: 'column1',
					title: 'Location',
					taskIds: array.reduce((a, v) => {
						if (!currentState.includes(v._id)) {
							return [...a, v._id];
						} else return [...a];
					}, []),
				},
				column2: {
					id: 'column2',
					title: 'Trip',
					taskIds: state.columns.column2.taskIds,
				},
			},
			columnOrder: ['column1', 'column2'],
		});
	};

	const onDragEnd = (result) => {
		const { destination, source, draggableId } = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const start = state.columns[source.droppableId];
		const finish = state.columns[destination.droppableId];

		if (start === finish) {
			const newTaskIds = Array.from(start.taskIds);
			newTaskIds.splice(source.index, 1);
			newTaskIds.splice(destination.index, 0, draggableId);

			const newColumn = {
				...start,
				taskIds: newTaskIds,
			};

			const newState = {
				...state,
				columns: {
					...state.columns,
					[newColumn.id]: newColumn,
				},
			};

			setState(newState);
			return;
		}

		// Moving from one list to another
		const startTaskIds = Array.from(start.taskIds);
		startTaskIds.splice(source.index, 1);
		const newStart = {
			...start,
			taskIds: startTaskIds,
		};

		const finishTaskIds = Array.from(finish.taskIds);
		finishTaskIds.splice(destination.index, 0, draggableId);
		const newFinish = {
			...finish,
			taskIds: finishTaskIds,
		};

		const newState = {
			...state,
			columns: {
				...state.columns,
				[newStart._id]: newStart,
				[newFinish._id]: newFinish,
			},
		};
		setState(newState);
	};

	return { onDragEnd, state, setState, updateState, tasks };
}

const useMapBoxRoute = (state, markers) => {
	const [route, setRoute] = useState('');
	const trip = state.columns.column2.taskIds.reduce((a, v) => {
		const item = markers.findIndex((e) => e._id === v);
		return [...a, markers[item]];
	}, []);
	const tripCoordinates = trip.reduce((a, v) => {
		const t1 = v.longitude;
		const comma = ',';
		const t2 = v.latitude;
		const colin = ';';
		return a.concat(colin, t1, comma, t2);
	}, '');

	const TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;
	const profile = 'driving';
	const coordinates = `116.139958,-8.867958${tripCoordinates};116.139958,-8.867958`;
	const url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinates}?alternatives=false&geometries=geojson&overview=simplified&steps=false&access_token=${TOKEN}`;

	useEffect(() => {
		const callAPI = async () => {
			try {
				const res = await fetch(url);
				const data = await res.json();
				setRoute(data);
			} catch (err) {
				// console.log('err', err);
			}
		};
		callAPI();
	}, [state]);

	return { route, trip };
};

const useRouteInfo = (route) => {
	const distance = (route.distance / 1000).toFixed(2);

	function convertTime(sec) {
		var hours = Math.floor(sec / 3600);
		hours >= 1 ? (sec = sec - hours * 3600) : (hours = '00');
		var min = Math.floor(sec / 60);
		min >= 1 ? (sec = sec - min * 60) : (min = '00');
		sec < 1 ? (sec = '00') : void 0;

		min.toString().length == 1 ? (min = '0' + min) : void 0;
		sec.toString().length == 1 ? (sec = '0' + sec) : void 0;

		return hours + ':' + min;
	}
	const duration = convertTime(route.duration);

	return { distance, duration };
};

function useWindowDimensions() {
	const hasWindow = typeof window !== 'undefined';

	function getWindowDimensions() {
		const width = hasWindow ? window.innerWidth : null;
		const height = hasWindow ? window.innerHeight : null;
		return {
			width,
			height,
		};
	}

	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		if (hasWindow) {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, [hasWindow]);

	return windowDimensions;
}

export { useCursorPosition, useCursorChange, useWrap, useCarousel, useParallax, useParallaxInView, useFilter, useDragAndDrop, useMapBoxRoute, useRouteInfo, useWindowDimensions };
