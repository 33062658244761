import React, { Children, useRef, useEffect } from 'react';
import { useInView, useScroll } from 'framer-motion';
import { GridItem_Section } from '../../styles/base/_base.styles';
import { useCursorChange, useParallax } from '../../lib/hooks';
import { scrollToRef } from '../../lib/libFunctions';
import CursorComp from '../cursor/cursor';
import { inViewOptions, motionFadeInObject } from '../../styles/base/_animations.styles';

const Section = ({ children, cursorsize, nocursor, ...props }) => {
	const [size, setSize, visible, setVisible] = useCursorChange();
	const ref = useRef(null);
	const isInView = useInView(ref, { margin: '100px 0px 100px 0px', ...inViewOptions });
	const showCursor = !nocursor;
	return (
		<GridItem_Section ref={ref} isInView={isInView} {...motionFadeInObject(isInView)} size={props.size} style={{ cursor: 'none' }} onMouseEnter={setVisible} onMouseLeave={setVisible} {...props}>
			{showCursor && <CursorComp cursorsize={cursorsize} size={size} visible={visible} />}
			{Children.map(children, (child) => {
				if (child.props.setsize) {
					return React.cloneElement(child, { onMouseEnter: setSize, onMouseLeave: setSize });
				} else if (child.props.setvisible) {
					return React.cloneElement(child, { onMouseEnter: setVisible, onMouseLeave: setVisible });
				} else {
					return React.cloneElement(child, {});
				}
			})}
		</GridItem_Section>
	);
};

export { Section };
