import imageUrlBuilder from '@sanity/image-url';
import { buildFileUrl } from '@sanity/asset-utils';
import { createClient } from 'next-sanity';
import { config } from '../client';
import BlockContent from '@sanity/block-content-to-react';
import { groq } from 'next-sanity';

const builder = imageUrlBuilder(createClient(config));

function sanityFileUrl(document) {
	const url = buildFileUrl({ ...config, assetId: document.document.asset._ref.slice(5, -4), type: 'file', vanityFilename: null, extension: 'pdf' });
	return url;
}

function sanityVideoUrl(document) {
	const url = buildFileUrl({ ...config, assetId: document.videoFile.asset._ref.slice(5, -4), type: 'file', vanityFilename: null, extension: 'mp4' });
	return url;
}

function sanitySrcUrl(source) {
	const src = source && builder.image(source).url();

	return src;
}
const callAll =
	(...fns) =>
	(...args) =>
		fns.forEach((fn) => fn?.(...args));

const scrollToRef = (ref) =>
	window.scrollTo({
		top: ref.current.offsetTop - 100,
		left: 100,
		behavior: 'smooth',
	});

const mapBoxDirectionsURL = (trip) => {
	const tripCoordinates = trip.reduce((a, v) => {
		const t1 = v.longitude;
		const comma = ',';
		const t2 = v.latitude;
		const colin = ';';
		return a.concat(colin, t1, comma, t2);
	}, '');

	const TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN;
	const profile = 'driving';
	const coordinates = `116.139958,-8.867958${tripCoordinates};116.139958,-8.867958`;
	const url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${coordinates}?alternatives=false&geometries=geojson&overview=simplified&steps=false&access_token=${TOKEN}`;

	return url;
};

function groqSanitySlugQuery(slug) {
	const query = groq`*[_type == 'room' && slug.current == "${slug}"]{...,
	'otherRooms': *[_type == 'room' && slug.current != "${slug}" && slug.current != 'villa']{...},'PageContactSection': 	*[_type == "section" && content[0]._type == "contact"]{...}
	}`;

	return query;
}

function groqSanityPageQuery(page) {
	const query = groq`*[_type == 'page' && slug.current == "${page}"]{..., 
		'modules': modules[]{...,
		_type == 'reference' => @->,
		_type != 'reference' => @,
		_type == 'roomsCarousel' => {
			array[]{..., room->}
		},
		_type == 'sectionCarousel' => {
			room->
		},
	},
	${page === 'explore-lombok' || page === 'a-boni-good-day' ? `'markers': *[_type == "exploreLombok"]{...}` : ''}
}`;

	return query;
}

function propsSanity(data) {
	const documents = Array.isArray(data) ? data : [data];
	const markers = data.markers;

	const combinedModules = documents.flatMap((doc) => doc.modules);

	const props = combinedModules.reduce((obj, cur) => {
		if (cur._type === 'section') {
			return { ...obj, [cur.name.replace(/\s/g, '')]: cur };
		} else if (cur._type === 'horizontalScroll') {
			return { ...obj, [cur.title.replace(/\s/g, '')]: cur };
		} else if (cur._type === 'headerTwoColumText') {
			return { ...obj, [cur.name]: cur };
		} else if (cur._type === 'carousel') {
			return { ...obj, [cur.name]: cur };
		} else if (cur._type === 'sectionCarousel') {
			return { ...obj, [cur.header.replace(/\s/g, '')]: cur };
		} else if (cur._type === 'documents') {
			return { ...obj, [cur.document.description.replace(/\s/g, '')]: cur };
		} else {
			return { ...obj, [cur._type]: cur };
		}
	}, {});

	return {
		data: props,
		seo: data[0].seo ? data[0].seo : { metaTitle: '', metaDesc: '' },
		...(data[0].markers && { markers: data[0].markers }),
	};
}

function groqSanityExploreLombokSlugQuery(slug) {
	function capitalizeFirstLetter(string) {
		return string.replace(/^./, string[0].toUpperCase());
	}

	const query = groq`*[_type == 'page' && slug.current == "explore-lombok"  ]{...,
		'modules': modules[]{...,
			_type == 'reference' => @->,
			_type != 'reference' => @,
			_type == 'roomsCarousel' => {
				array[]{..., room->}
			},
			_type == 'sectionCarousel' => {
				room->
			},
		},
		'markers': *[_type == "exploreLombok" && category == "${capitalizeFirstLetter(slug)}"]{...},
	'PageContactSection': 	*[_type == "section" && content[0]._type == "contact"]{...}
	}`;

	return query;
}

function handleBookingLinkClick() {
	// Use the gtag function to send an event to GA4
	gtag('event', 'booking_link_click_link_tree', {
		event_category: 'Navigation',
		event_label: 'Direct Booking Site Link Tree',
	});
}

function handleWhatsAppLinkClick() {
	// Use the gtag function to send an event to GA4
	gtag('event', 'whatsapp_link_click_link_tree', {
		event_category: 'Navigation',
		event_label: 'Whats App Link Tree',
	});
}

function handleWebsiteLinkClick() {
	// Use the gtag function to send an event to GA4
	gtag('event', 'website_link_click_link_tree', {
		event_category: 'Navigation',
		event_label: 'Website Click Link Tree',
	});
}

function handleMapsLinkClick() {
	// Use the gtag function to send an event to GA4
	gtag('event', 'maps_link_click_link_tree', {
		event_category: 'Navigation',
		event_label: 'Maps Click Link Tree',
	});
}

function handleWhatsAppPageClick() {
	// Use the gtag function to send an event to GA4
	gtag('event', 'whatsapp_link_click_page', {
		event_category: 'Navigation',
		event_label: 'Whats App Link Page',
	});
}

// function handleGoogleAnalyticsEvent(name, event_category, event_label) {
// 	// Use the gtag function to send an event to GA4
// 	gtag('event', name, {
// 		event_category,
// 		event_label,
// 	});
// }

// function handleGoogleAnalyticsConversion(event_name) {
// 	gtag('event', 'conversion', {
// 		event_name: event_name,
// 		value: 1, // Optionally, specify the conversion value if applicable
// 	});
// }

function createEventOptions(slug) {
	return {
		whatsapp_click: {
			event: 'whatsapp_click',
			category: 'Contact Methods',
			label: 'Homepage Icon',
			page: slug,
		},
		footer_email_click: {
			event: 'footer_email_click',
			category: 'Contact Methods',
			label: 'Footer Email',
			page: slug,
		},
		footer_whatsapp_click: {
			event: 'footer_whatsapp_click',
			category: 'Contact Methods',
			label: 'Footer WhatsApp',
			page: slug,
		},
		footer_map_click: {
			event: 'footer_map_click',
			category: 'Contact Methods',
			label: 'Footer Google Maps',
			page: slug,
		},
		contact_form_submit: {
			event: 'contact_form_submit',
			category: 'Contact Methods',
			label: 'Contact Page Form',
			page: slug,
		},
		booking_click_header: {
			event: 'booking_click_header',
			category: 'Booking Engine',
			label: 'Header',
			page: slug,
		},
		booking_click_banner: {
			event: 'booking_click_banner',
			category: 'Booking Engine',
			label: 'Banner',
			page: slug,
		},
		menu_download: {
			event: 'menu_download',
			category: 'User Engagement',
			label: 'Menu PDF',
			page: slug,
		},
		linktree_whatsapp_click: {
			event: 'linktree_whatsapp_click',
			category: 'Linktree',
			label: 'WhatsApp Link',
			page: slug,
		},
		linktree_website_click: {
			event: 'linktree_website_click',
			category: 'Linktree',
			label: 'Website Link',
			page: slug,
		},
		linktree_booking_click: {
			event: 'linktree_booking_click',
			category: 'Linktree',
			label: 'Booking Link',
			page: slug,
		},
		linktree_maps_click: {
			event: 'linktree_maps_click',
			category: 'Linktree',
			label: 'Maps Link',
			page: slug,
		},
		marketing_booking_click: {
			event: 'marketing_booking_click',
			category: 'Marketing',
			label: 'Marketing',
			page: slug,
		},
	};
}

export {
	callAll,
	scrollToRef,
	sanitySrcUrl,
	sanityFileUrl,
	propsSanity,
	groqSanityPageQuery,
	groqSanitySlugQuery,
	groqSanityExploreLombokSlugQuery,
	sanityVideoUrl,
	handleBookingLinkClick,
	handleWhatsAppLinkClick,
	handleWebsiteLinkClick,
	handleMapsLinkClick,
	handleWhatsAppPageClick,
	createEventOptions,
};
